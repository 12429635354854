import React from 'react';

import styles from './ListThumbnailImages.module.scss';
import ListThumbnailMissingImage from './ListThumbnailMissingImage';

import Image from 'skapa/Image';

import CountBadge from 'components/CountBadge';

type ListThumbnailImagesProps = {
    items: { images: { url: string }[]; alt: string }[];
};

const ListThumbnailImages: React.FC<ListThumbnailImagesProps> = ({ items }) => {
    const itemsCount = items.length || 0;
    const showSecondSmallThumbnail = itemsCount >= 2;
    const showThirdSmallThumbnail = itemsCount === 3;
    const showCountBadge = itemsCount >= 4;

    const firstImageUrl =
        items?.[items?.length - 1]?.images?.[2]?.url ||
        items?.[items?.length - 1]?.images?.[1]?.url ||
        items?.[items?.length - 1]?.images?.[0]?.url;

    return (
        <div className={styles.imagesContainer}>
            <div className={styles.imageWrapper}>
                {itemsCount === 0 ? (
                    <ListThumbnailMissingImage />
                ) : (
                    <Image
                        src={firstImageUrl}
                        alt={items?.[items?.length - 1]?.alt}
                        className={styles.imagePlaceholder}
                    />
                )}
            </div>

            {showSecondSmallThumbnail && (
                <div className={styles.imageWrapper}>
                    <Image
                        src={items?.[items?.length - 2]?.images?.[2]?.url}
                        alt={items?.[items?.length - 2]?.alt}
                        className={styles.imagePlaceholder}
                    />
                </div>
            )}

            {showThirdSmallThumbnail && (
                <div className={styles.imageWrapper}>
                    <Image
                        src={items?.[items?.length - 3]?.images?.[3]?.url}
                        alt={items?.[items?.length - 3]?.alt}
                        className={styles.imagePlaceholder}
                    />
                </div>
            )}

            {showCountBadge && (
                <div className={styles.imageWrapper}>
                    <CountBadge count={itemsCount - 2} />
                </div>
            )}
        </div>
    );
};

export default ListThumbnailImages;
