const mobileRegex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet/i;

const matchUserAgent = (regex: RegExp) => {
    if (typeof window !== 'undefined') {
        const userAgent = navigator.userAgent;
        return !!userAgent.match(regex);
    }
    return false;
};

export const isMobileDevice = () => {
    if (typeof window !== 'undefined') {
        const isTouchEvent =
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.maxTouchPoints > 0;

        const isViewportMobile = window.innerWidth <= 768;

        return matchUserAgent(mobileRegex) || isTouchEvent || isViewportMobile;
    }

    return false;
};
