import React from 'react';

import styles from './ListThumbnail.module.scss';
import { space150, space550 } from '@ingka/variables';
import { breakpoint as Breakpoint, useBreakpoint } from '@ingka/viewport-hooks';
import Skeleton from '@skapa/Skeleton';

const getMaxNumberOfItems = (bp?: Breakpoint) => {
    switch (bp) {
        case 'sm':
            return 1;
        case 'md':
            return 3;
        default:
            return 4;
    }
};

const ListThumbnailSkeleton: React.FC = () => {
    const breakpoint = useBreakpoint('sm', 'md', 'lg', 'xl');
    return (
        <>
            {new Array(getMaxNumberOfItems(breakpoint)).fill(0).map((_, i) => (
                <div className={styles.container} key={i}>
                    <div>
                        <Skeleton width="50%" height={space150} />
                        <Skeleton width="25%" height={space150} />
                    </div>
                    <Skeleton width="100%" height={space550} />
                </div>
            ))}
        </>
    );
};

export default ListThumbnailSkeleton;
