import useLocale from './useLocale';
import dayjs from 'dayjs';

const useIsoDateToLocalFormat = () => {
    const { locale } = useLocale();
    const isoDateToLocalFormat = ({
        isoDate,
        options,
        isAlternativeFormat,
    }: {
        isoDate: string;
        options?: Intl.DateTimeFormatOptions;
        isAlternativeFormat?: boolean;
    }) => {
        const DateFormatter = new Intl.DateTimeFormat(locale, options);
        const date = new Date(isoDate);
        // For now New zealand is the only locale that needs alternative date format so we opted for a simple, non-invasive solution.
        // We should however replace Intl.DateTimeFormat fully with dayjs if we get more locales that need a special format in the future
        return isAlternativeFormat
            ? dayjs(isoDate).format('DD MMM YYYY')
            : DateFormatter.format(date);
    };
    return isoDateToLocalFormat;
};

export default useIsoDateToLocalFormat;
