/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import styles from './ListThumbnail.module.scss';
import ListThumbnailImages from './ListThumbnailImages';
import { useModal } from '@components/Modals/useModal';
import useFormatDateText from '@hooks/useFormatDateText';
import useIsCustomerInStore from '@hooks/useIsCustomerInStore';
import ellipsisIcon from '@ingka/ssr-icon/paths/ellipses-vertical';
import Text from '@skapa/Text';
import useTranslations from 'context/Translations';

import { SourceList } from 'apis/types';

import Button from 'skapa/Button';

type ListThumbnailProps = {
    list: SourceList;
};
const ListThumbnail: React.FC<ListThumbnailProps> = ({ list }) => {
    const { showModal } = useModal();
    const isCustomerInStore = useIsCustomerInStore();
    const translate = useTranslations();
    const formatDateText = useFormatDateText();
    const dateText = formatDateText(list.updated);

    const linkHref = isCustomerInStore
        ? `${list.listId}?view=store`
        : `${list.listId}?view=retail`;
    const itemImages = list.items.map(item => ({
        images: item.product?.images || [],
        alt: item.product?.name || '',
    }));

    const designImages = list.designs.map(design => ({
        images: design.images || [],
        alt: design.configurationId || '',
    }));
    return (
        <div className={styles.wrapper}>
            <Button
                type="plain"
                data-testid="select-list-item"
                className={styles.container}
                onClick={() => (window.location.href = linkHref)}
                aria-label={`${translate('LISTOVERVIEW_LISTS_THUMBNAIL_ARIA', {
                    listName: list.name,
                })}, ${dateText}`}
            >
                <div className={styles.content}>
                    <Text
                        headingSize="s"
                        tagName="h4"
                        className={styles.listName}
                    >
                        {list.name}
                    </Text>
                    <Text bodySize="m" tagName="p" className={styles.caption}>
                        {dateText}
                    </Text>
                </div>
                <ListThumbnailImages items={[...itemImages, ...designImages]} />
            </Button>
            <Button
                className={styles.settingsButton}
                small
                iconOnly
                type="tertiary"
                ssrIcon={ellipsisIcon}
                aria-label={translate('LISTDETAIL_SETTINGS_TITLE2')}
                onClick={e => {
                    e.stopPropagation();
                    showModal('ListMenu', {
                        list,
                        isLandingPage: true,
                    });
                }}
            />
        </div>
    );
};

export default ListThumbnail;
